import React, { useState } from "react";

export default function VerticalCoupon({ code, src, name, desc, percent }) {
  const [copied, setCopiedState] = useState(false);
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(code).then(() => {
        setCopiedState(true);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = code;
      document.body.appendChild(textArea);
      //   textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        setCopiedState(true);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  };
  return (
    <div className="flex transition-all ease-in-out duration-500 hover:cursor-pointer hover:sh-2 hover:scale-[1.03] bg-white relative mx-auto my-2 p-2 w-[370px] ">
      <section className="  w-[250px] border-l border-[#0D8F75] border-dashed">
        <section className=" flex gap-2 items-center">
          <img
            src={src}
            className=" h-[30px] w-[30px] mx-1 rounded-full "
            alt="code"
          />
          <h1 className=" text-[#0D8F75] font-[700] text-[15px] ">{name}</h1>
        </section>
        <p className=" w-[75%] mx-2 my-4 text-[#BFBFBF] font-[400] text-[14px] ">
          {desc}
        </p>
      </section>
      <section className=" w-[120px] py-2  ">
        <section className=" flex  justify-center items-center ">
          <h3 className=" text-[#CCCCCC] text-[13px] font-[700] ">يصل الي</h3>
          <h1 className=" text-black text-[20px] font-[700] ">{percent}</h1>
        </section>
        <section className=" mt-12 px-2">
          <button
            onClick={handleCopy}
            className=" bg-[#0D8F75] text-white text-[11px] rounded-[5px] font-[700] text-center mx-auto h-[25px] w-[96px]  "
          >
            {!copied ? (
              <>
                {" "}
                نسخ الكود <i className="fa-regular fa-clone mr-2"></i>
              </>
            ) : (
              <>
                تم النسخ <i className="fa-solid fa-check mr-2"></i>
              </>
            )}
          </button>
        </section>
      </section>
      <section className=" absolute top-[-15px] left-[108px] w-[30px] h-[30px] bg-[#F2F3F7] rounded-full "></section>
      <section className=" absolute bottom-[-15px] left-[108px] w-[30px] h-[30px] bg-[#F2F3F7] rounded-full "></section>
    </div>
  );
}

import { useEffect, useState } from "react";
import StoreWide from "../../pages/Home/StoreWide";
import StoreHorzint from "../../pages/Home/StoreHorzint";
import useFetch from "../../utils/useGet";

const StoresLayout = ({ sectionId }) => {
  const [shapeWide, setShapeWide] = useState(true);
  const [stores, setStores] = useState([]);
  const [storesForSearsh, setStoresForSearsh] = useState([]);

  const allStores = useFetch("user/stores/all").data;

  useEffect(() => {
    if (allStores) {
      setStores(allStores);
      setStoresForSearsh(allStores);
    }
  }, [allStores]);
  useEffect(() => {
    let searched = storesForSearsh;
    if (sectionId !== "all") {
      searched = searched.filter((e) => e.section_id == sectionId);
    }
    setStores(searched);
  }, [sectionId]);

  return (
    <section>
      <div //titles
        className=" px-3 flex items-center justify-between"
      >
        <h1 className="subtitle">{stores.length} متجر</h1>
        <div className=" flex items-center justify-around gap-3">
          <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
            <i className="fa-solid fa-sliders"></i>
          </section>
          {/* <button onClick={toggleOpenFilter}>
            <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
              <i className="fa-solid fa-filter"></i>
            </section>
          </button> */}
          <button onClick={() => setShapeWide(!shapeWide)}>
            <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
              <i
                className={`fa-solid fa-pause text-xl ${
                  !shapeWide && "rotate-90"
                }`}
              ></i>
            </section>
          </button>
        </div>
      </div>
      <div className=" px-3 w-full overflow-hidden flex flex-wrap my-6">
        {stores.map((e, index) => {
          if (shapeWide) {
            return (
              <StoreWide
                key={index}
                path={`/store?id=${e.id}`}
                name={e.name}
                src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                desc={e.description}
              />
            );
          } else {
            return (
              <StoreHorzint
                key={index}
                path={`/store?id=${e.id}`}
                name={e.name}
                src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                desc={e.description}
              />
            );
          }
        })}
      </div>
    </section>
  );
};
export default StoresLayout;

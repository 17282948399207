import React from "react";
import DownBar from "./DownBar";
import Title from "./Title";

export default function SecondLayout({ children, text }) {
  return (
    <div className="relative  pb-24 bg-[#F2F3F7] min-h-screen">
      <div className="relative z-10">
        <Title text={text} />
      </div>

      <div className="absolute pb-20 bg-[#F2F3F7]  top-24 left-0 w-full z-0">
        {children}
      </div>

      <div className="relative z-10">
        <DownBar />
      </div>
    </div>
  );
}

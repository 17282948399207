import React from "react";

export default function Notifi({ name, desc, time }) {
  
  return (
    <section className={` flex items-center justify-between p-1 my-2 noti w-full`}>
      <section>
        <h1 className="text-[16px] font-[700] text-[#254570]  ">{name}</h1>
        <h4 className=" my-3 text-[#C8CFD5] text-[12px] font-[700] ">{desc}</h4>
      </section>
      <section className=" text-[10px] text-[#C8CFD5] font-[10px] mx-1 ">
        {time}
      </section>
    </section>
  );
}

import { useEffect, useState } from "react";
import Title from "../../components/layout/Title";
import VerticalCoupon from "../../components/assets/VerticalCoupon";
import HorizCoupon from "../../components/assets/HorizCoupon";
import DownOutPut from "../../components/assets/DownOutPut";
import { useLocation } from "react-router";
import useFetch from "../../utils/useGet";
import usePostJSON from "../../utils/usePostJson";
import imgPlaceholder from "../../placeholder-image.webp";

const initInfo = {
  name: "",
  image: "",
  desc: "",
  featImages: ["", "", ""],
};

export default function Store() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("id");
  const [info, setInfo] = useState(initInfo);
  const { name, image, desc, featImages } = info;
  const { data } = useFetch(`user/stores/show/${id}`);
  const [codes, setCodes] = useState([]);
  const [favCop, setFavCop] = useState(false);
  const [reload, setReload] = useState(false);
  const [shapeWide, setShapeWide] = useState(true);
  const [fav, setFav] = useState(false);
  const allFavStores = useFetch("user/stores/store-favorites", reload);

  useEffect(() => {
    if (data) {
      setInfo((prev) => ({
        ...prev,
        name: data.name,
        image: `${process.env.REACT_APP_URL}/${data.image_path}`,
        desc: data.description,
      }));
      setCodes(data.coupons);
    }
  }, [data]);

  useEffect(() => {
    if (allFavStores.data) {
      try {
        let fa = allFavStores.data.filter((f) => f.store_id == id);
        fa.length > 0 && setFav(true);
      } catch {
        setFav(false);
      }
    }
  }, [allFavStores]);
  const removeFavHandeller = () => {
    setFav(!fav);
  };
  const { postData } = usePostJSON();
  const addFavHandeller = async () => {
    const data = {
      store_id: id,
    };
    const res = await postData("user/stores/store-favorites", data);

    console.log(res);

    if (res) {
      setFav(true);
    }
  };

  const [openCopoun, setOpenCopoun] = useState(false);

  const closeCoupon = () => {
    setOpenCopoun(false);
    setCoupon({
      code: "",
      name: "",
      src: "",
      link: "",
      percent: "",
      desc: "",
    });
    // setFavCop(false);
    setCopiedState(false);
  };
  const [copoun, setCoupon] = useState({
    id: "",
    code: "",
    name: "",
    src: "",
    link: "",
    percent: "",
    desc: "",
  });

  const re = () => {
    setReload(!reload);
  };

  const handelFavCop = async () => {
    if (!favCop) {
      const data = { coupon_id: copoun.id };
      const res = await postData(`user/favorites/create`, data);
      if (res) {
        setFavCop(true);
        re();
      }
    }
  };
  const [copied, setCopiedState] = useState(false);
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(copoun.code).then(() => {
        setCopiedState(true);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = copoun.code;
      document.body.appendChild(textArea);
      //   textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        setCopiedState(true);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  };
  const allFavCoups = useFetch(`user/favorites/all`, reload).data;

  return (
    <div className=" min-h-screen bg-[#F2F3F7]">
      <Title text={name} />
      <div className=" p-3">
        <section className=" relative my-2 sh-2 p-6 flex items-center gap-3 bg-[white] rounded-[10px]  ">
          <img
            className=" w-[80px] h-[80px] rounded-full "
            src={image}
            alt="store Logo"
          />
          <section>
            <h1 className=" text-[#0D8F75] font-[700] text-[22px] mb-3 ">
              {name}
            </h1>
            <h4 className=" text-[#999999] font-[700] text-[12px] ">{desc}</h4>
          </section>
          <button
            onClick={fav ? removeFavHandeller : addFavHandeller}
            className={`absolute top-3 left-3 flex items-center justify-center px-2 py-1 border text-[9px] font-[700] w-[100px] h-[25px] border-[#0D8F75] rounded-md transition-colors duration-300 ease-in ${
              fav ? "text-white bg-[#0D8F75]" : "text-[#0D8F75] bg-white"
            }`}
          >
            {fav ? "المفضلة" : "اضف الي المفضلة"}
          </button>
        </section>

        <div className="my-6 flex gap-x-10 justify-center items-center">
          {featImages.map((src, i) => (
            <img
              key={src + name + i}
              className="size-24 lg:size-32 rounded-xl shadow-md"
              src={src || imgPlaceholder}
              alt={`${name} store feature image -${i + 1}`}
            />
          ))}
        </div>

        <div className=" px-3 flex items-center justify-between">
          <h1 className="subtitle"> جميع العروض</h1>
          <div className=" flex items-center justify-around gap-3">
            <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
              <i className="fa-solid fa-sliders"></i>
            </section>
            <button onClick={() => setShapeWide(!shapeWide)}>
              <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
                <i
                  className={`fa-solid fa-pause text-xl ${
                    !shapeWide && "rotate-90"
                  }`}
                ></i>
              </section>
            </button>
          </div>
        </div>
        <div className=" py-6  flex items-center gap-4 md:gap-6 lg:gap-10 max-w-full   justify-start flex-wrap">
          {codes.map((e, index) => {
            if (shapeWide) {
              return (
                <div
                  key={e + index}
                  className="cursor-pointer mx-auto"
                  onClick={() => {
                    setCoupon(e);
                    setOpenCopoun(true);
                    let fa = allFavCoups.filter((cop) => cop.coupon_id == e.id);
                    fa.length > 0 ? setFavCop(true) : setFavCop(false);
                  }}
                >
                  <VerticalCoupon
                    code={e.code}
                    name={e.name}
                    src={image}
                    desc={e.description}
                    percent={`${e.discount}%`}
                  />
                </div>
              );
            } else {
              return (
                <div
                  key={e + index}
                  className="cursor-pointer mx-auto"
                  onClick={() => {
                    setCoupon(e);
                    setOpenCopoun(true);
                  }}
                >
                  <HorizCoupon
                    code={e.code}
                    name={e.name}
                    src={image}
                    desc={e.description}
                    percent={`${e.discount}%`}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
      {
        <DownOutPut close={closeCoupon} open={openCopoun}>
          <div className=" px-2">
            <section className=" border-b relative my-2 p-6 flex items-center gap-3 bg-[white]   ">
              <img
                className=" w-[80px] h-[80px] rounded-full "
                src={image}
                alt="store Logo"
              />
              <section>
                <h1 className=" text-[#0D8F75] font-[700] text-[22px] mb-3 ">
                  {name}
                </h1>
                <h4 className=" text-[#999999] font-[700] text-[12px] ">
                  {desc}
                </h4>
              </section>
              {/* <section className=" absolute top-3 left-3">
                {fav ? (
                  <h1
                    // onClick={removeFavHandeller}
                    className=" flex items-center justify-center border-[#0D8F75] border text-[9px] font-[700] text-[white] bg-[#0D8F75] w-[100px] h-[25px] rounded-md"
                  >
                    المفضلة
                  </h1>
                ) : (
                  <button
                    onClick={addFavHandeller}
                    className=" border-[#0D8F75] border text-[9px] font-[700] text-[#0D8F75] w-[100px] h-[25px] rounded-md"
                  >
                    اضف الي المفضلة
                  </button>
                )}
              </section> */}
            </section>
            <section className=" border-b  my-2 p-6  text-center  ">
              <section className=" flex gap-1 justify-center items-center ">
                <h3 className=" text-[#CCCCCC] text-[13px] font-[700] ">
                  يصل الي
                </h3>
                <h1 className=" text-black text-[22px] font-[700] ">
                  {copoun.discount}
                </h1>
              </section>
              <section className=" bg-[#DBEEEA] w-[115px] rounded-[5px]  mx-auto py-2 px-3 my-2 flex gap-1 text-[15px] font-[700] text-[#0D8F75] justify-center">
                خصم
                <span className="border-2 border-[#0D8F75] text-[#0D8F75] rounded-full h-[25px] flex items-center justify-center w-[25px]">
                  <i className="fa-solid fa-percent"></i>
                </span>
              </section>
            </section>
            <section className=" border-b  my-2 p-6    ">
              <h1 className=" my-2 text-[20px] font-[700] ">التفاصيل</h1>
              <h4 className=" text-[#999999] font-[700] text-[12px] ">
                {copoun.description}
              </h4>
            </section>
            <section className=" flex items-center justify-between md:justify-around border-b border-dashed  my-2 p-6    ">
              {/* <section className=" text-center w-fit">
                <section className=" flex items-center justify-center w-[65px] h-[65px] rounded-full bg-[#E7F4F1] text-[#0D8F75] text-xl mb-2 ">
                  <i className="fa-solid fa-arrow-up-from-bracket"></i>
                </section>
                <h1 className=" text-[15px] font-[700] text-[#0D8F75] ">
                  مشاركة
                </h1>
              </section> */}
              <a rel="noreferrer" target="_blank" href={copoun.link}>
                <section className=" text-center w-fit">
                  <section className=" flex items-center justify-center w-[65px] h-[65px] rounded-full bg-[#E7F4F1] text-[#0D8F75] text-xl mb-2 ">
                    <i className="fa-solid fa-bag-shopping"></i>
                  </section>
                  <h1 className=" text-[15px] font-[700] text-[#0D8F75] ">
                    تسوق
                  </h1>
                </section>
              </a>
              {/* <button onClick={handelFavCop}>
                <section className=" text-center w-fit">
                  <section className=" flex items-center justify-center w-[65px] h-[65px] rounded-full bg-[#E7F4F1] text-[#0D8F75] text-xl mb-2 ">
                    {favCop ? (
                      <i className="fa-solid fa-heart"></i>
                    ) : (
                      <i className="fa-regular fa-heart"></i>
                    )}
                  </section>
                  <h1 className=" text-[15px] font-[700] text-[#0D8F75] ">
                    المفضلة
                  </h1>
                </section>
              </button> */}
            </section>
            <section className="my-2 p-3">
              <section className=" relative text-center p-3 flex items-center justify-center bg-[#F5F5F5] rounded-full ">
                <h1 className=" text-[18px] font-[700] ">{copoun.code}</h1>
                <section className=" left-1 absolute">
                  {!copied ? (
                    <>
                      <button
                        onClick={handleCopy}
                        className=" bg-white text-[12px] p-2 text-[#0D8F75] font-[700] rounded-full "
                      >
                        نسخ الكود <i className="fa-regular fa-clone mr-2"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button className=" text-white text-[12px] p-2 bg-[#0D8F75] font-[700] rounded-full ">
                        تم النسخ <i className="fa-solid fa-check mr-2"></i>
                      </button>
                    </>
                  )}
                </section>
              </section>
            </section>
          </div>
        </DownOutPut>
      }
    </div>
  );
}

import React, { useState } from "react";
import Title from "../../components/layout/Title";
import { Link } from "react-router-dom";
import usePost from "../../utils/usePost";

export default function Join() {
  const [info, setInfo] = useState({
    name: "",
    phone: "",
    link: "",
    desc: "",
    file: null,
  });

  const { name, phone, link, desc, file } = info;

  const handelChange = (e) => {
    const { value, name } = e.target;

    setInfo({ ...info, [name]: value });
  };

  const [sended, setSended] = useState(false);

  const { postData } = usePost();
  const sendHandeller = async () => {
    const formdata = new FormData();
    formdata.append("store_name", name);
    formdata.append("store_description", desc);
    formdata.append("store_link", link);
    formdata.append("store_image", file);
    formdata.append("contact_number", phone);
    const res = await postData("user/storeOrder/create", formdata);
    if (res) {
      setSended(true);
    }
  };
  return (
    <div className=" min-h-screen bg-[#F2F3F7]">
      <Title text={`انضمام الي العائلة`} />
      {sended ? (
        <>
          <section className=" bg-white rounded-[10px] p-3 text-center w-full md:w-[500px] mx-auto my-32">
            <i className="fa-solid fa-circle-check text-[#0D8F75] text-[110px] my-10"></i>
            <h1 className=" text-[20px] font-[700] text-[#372732]  ">
              تم ارسال طلبك بنجاح
            </h1>
            <h4 className=" text-[14px] font-[700] text-[#CCCCCC] my-4 ">
              سيتم التواصل معك بعد مراجعة البيانات
            </h4>
            <Link to="/">
              <button className="LogBtn">عودة الي الصفحة الرئيسية</button>
            </Link>
          </section>
        </>
      ) : (
        <div className=" my-10 rounded-[10px] bg-white p-[20px] w-full md:w-fit mx-auto ">
          <section //Titles
            className="text-center my-4"
          >
            <h1 className=" text-[20px] font-[700] text-[#254570] ">
              انضمام الي العائلة
            </h1>
            <h4 className=" text-[12px] font-[700] text-[#CCCCCC] my-3 ">
              قم بمليء الفورم بتفاصيل المتجر
            </h4>
          </section>
          <section //form
            className=" w-full md:w-[500px]"
          >
            <section //name
              className=" my-4"
            >
              <h4 className=" imputTitle">اسم المتجر </h4>
              <input
                className="input2"
                name="name"
                onChange={handelChange}
                value={name}
                type="text"
                placeholder=" قم بكتابة اسم المتجر"
              />
            </section>
            <section //desc
              className=" my-4"
            >
              <h4 className=" imputTitle"> وصف المتجر</h4>
              <input
                className="input2"
                name="desc"
                onChange={handelChange}
                value={desc}
                type="text"
                placeholder=" قم بكتابة وصف المتجر"
              />
            </section>
            <section //link
              className=" my-4"
            >
              <h4 className=" imputTitle">لينك المتجر</h4>
              <input
                className="input2"
                name="link"
                onChange={handelChange}
                value={link}
                type="text"
                placeholder=" قم بكتابة لينك المتجر"
              />
            </section>
            <div //image
              className=" my-2  w-full"
            >
              <h4 className=" imputTitle">صورة المتجر</h4>
              {file ? (
                // if file or else
                <div className="relative">
                  {/* Display Image */}
                  <img
                    alt="cover"
                    className="max-w-full max-h-48 border rounded-md shadow-md mx-auto"
                    src={URL.createObjectURL(file)}
                  />
                </div>
              ) : (
                <div className=" border rounded-[10px] my-2 p-3 h-[100px] flex items-center justify-center">
                  {/* Upload Image */}
                  <section>
                    <label
                      className="po cursor-pointer h-full w-full"
                      htmlFor="file"
                    >
                      <span className="w-full flex justify-center">
                        <i className="fa-solid fa-arrow-up-from-bracket w-4 text-[#6D4E1F] text-[18px] text-center"></i>
                      </span>
                      <h4 className="text-[#A3AED0] text-[12px] my-4">
                        قم برفع ملفات PNG , JPG
                      </h4>
                    </label>
                    <input
                      required
                      type="file"
                      id="file"
                      onChange={(e) => {
                        setInfo({ ...info, file: e.target.files[0] });
                      }}
                      style={{ display: "none" }}
                    />
                  </section>
                </div>
              )}
            </div>
            <section //phone
              className=" my-4"
            >
              <h4 className=" imputTitle">رقم للتواصل</h4>
              <input
                className="input2"
                name="phone"
                onChange={handelChange}
                value={phone}
                type="text"
                placeholder=" قم بكتابة رقم للتواصل"
              />
            </section>
          </section>
          <section //BTNs
            className=" my-4"
          >
            <button onClick={sendHandeller} className="LogBtn">
              ارسال
            </button>
          </section>
        </div>
      )}
    </div>
  );
}

import { useEffect, useState } from "react";
import useFetch from "../../utils/useGet";
import SecondLayout from "../../components/layout/SecondLayout";
import ProductCard from "./ProductCard";

const Products = () => {
  const [products, setProducts] = useState([]);
  // const [shapeWide, setShapeWide] = useState(true);
  // const [productsForSearsh, setProductssForSearsh] = useState([]);
  // [x] make sure the products is fetched sweetly!
  // const sectionId = "all";

  const allProducts = useFetch("user/products/all").data;

  useEffect(() => {
    if (allProducts) {
      setProducts(allProducts);
      // setProductssForSearsh(allProducts);
    }
  }, [allProducts]);
  // useEffect(() => {
  //   let searched = productsForSearsh;
  //   if (sectionId !== "all") {
  //     searched = searched.filter((e) => e.section_id === sectionId);
  //   }
  //   setProducts(searched);
  // }, [sectionId, productsForSearsh]);

  return (
    <SecondLayout text="المنتجات">
      <div className="py-4">
        <section>
          <div className=" px-3 flex items-center justify-between">
            <h1 className="subtitle">عدد {products.length} منتج</h1>
            {/*<div className=" flex items-center justify-around gap-3">
              <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
                <i className="fa-solid fa-sliders"></i>
              </section>
            <button onClick={toggleOpenFilter}>
            <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
              <i className="fa-solid fa-filter"></i>
            </section>
          </button> 
              <button onClick={() => setShapeWide(!shapeWide)}>
                <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
                  <i
                    className={`fa-solid fa-pause text-xl ${
                      !shapeWide && "rotate-90"
                    }`}
                  ></i>
                </section>
              </button>
            </div>*/}
          </div>
          <div className="px-3 w-full overflow-hidden grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-6">
            {products.map((e, index) => (
              <ProductCard
                key={index}
                path={e.url}
                name={e.title}
                src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                desc={e.description}
              />
            ))}
          </div>
        </section>
      </div>
    </SecondLayout>
  );
};
export default Products;

import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";

export default function LinkBar({ li, text ,icon }) {
  let path = useLocation().pathname;
  return (
    <>
      <NavLink to={`/${li}`}>
        <div
          className={` text-center pt-4 pb-2 ${
            path === `/${li}` && "border-t-[2px]"
          } border-[#0D8F75] mx-4 md:mx-8`}
        >
          <i
            className={`${icon} ${
              path === `/${li}` ? "text-[#0D8F75]" : "text-[#CCCCCC]"
            }  text-[22px] mb-2`}
          ></i>
          <h1
            className={` text-[12px] ${
              path === `/${li}` ? "text-[#0D8F75]" : "text-[#CCCCCC]"
            } font-[700]`}
          >
            {text}
          </h1>
        </div>
      </NavLink>
    </>
  );
}

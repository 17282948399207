import React, { useEffect, useState } from "react";
import VerticalCoupon from "../../components/assets/VerticalCoupon";
import useFetch from "../../utils/useGet";

export default function Coups() {
  const [coupons, setCoupons] = useState([]);
  const { data } = useFetch("user/favorites/all");

  const allStores = useFetch("user/stores/all").data;

  useEffect(() => {
    if (data) {
      setCoupons(data);
    }
  }, [data]);
  return (
    <div>
      <div className=" flex items-center justify-start flex-wrap">
        {coupons.map((e, index) => {
          let store = [];
          if (allStores) {
            store = allStores.filter((s) => s.id == e.coupon.store_id);
          }

          return (
            <VerticalCoupon
              key={index}
              code={e.coupon.code}
              name={e.coupon.name}
              src={
                store.length > 0
                  ? `${process.env.REACT_APP_URL}/${store[0].image_path}`
                  : "https://th.bing.com/th/id/OIP.9hetfdrodOfI9KzE_g_dDAAAAA?rs=1&pid=ImgDetMain"
              }
              desc={e.coupon.description}
              percent={`${e.coupon.discount}%`}
            />
          );
        })}
      </div>
      {coupons.length === 0 && (
        <div className=" text-center my-20 ">
          <i className="fa-solid fa-heart text-[#BFBFBF] text-8xl my-4 " />
          <h1 className=" text-[22px] font-[700] my-3">
            انت لا تتابع اي كوبونات
          </h1>
          <h4 className="text-[18px] text-[#CCCCCC] font-[700] my-4">
            تابع الكوبونات للحصول علي مفضلاتك
          </h4>
        </div>
      )}
    </div>
  );
}

import { Link } from "react-router-dom";

const ProductCard = ({ path, src, name, desc }) => {
  return (
    <Link
      className="mx-auto bg-white p-4 rounded-lg shadow-lg w-full hover:cursor-pointer hover:shadow-lg hover:scale-[1.01] transition-all ease-in-out duration-300"
      to={path}
    >
      <img
        className="mx-auto h-40 md:h-60 w-full object-contain rounded-md"
        src={src}
        alt={`${name} - store`}
      />
      <div className="mt-4 md:mt-6 w-full">
        <h3 className="subtitle">{name}</h3>
        <p className="text-[#999999] text-[12px] font-[700]">{desc}</p>
      </div>
    </Link>
  );
};
export default ProductCard;

import { Link } from "react-router-dom";

export default function SignUpSuccess() {
  return (
    <div className=" bg-white min-h-screen flex justify-center">
      <div className="px-2 md:border md:shadow-md md:rounded-md w-full md:w-fit my-2">
        <img className="w-[250px] mx-auto" src="./Images/logo.jpg" alt="Logo" />
        <div className="w-full p-2 md:w-[500px] flex flex-col items-center gap-4">
          <i className="fa-solid fa-circle-check text-[#0D8F75] text-[110px] mt-16 mb-8"></i>
          <h2 className="loginTitle text-base">تم إنشاء حسابك بنجاح</h2>
          <Link to="/" className="w-full">
            <button className="LogBtn shadow-md">الأنتقال إلي التطبيق</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

import { useState, useCallback, useContext } from "react";
import { load } from "./isLoadingStore";
import Cookies from "js-cookie";

const usePost = () => {
  const setLoading = useContext(load).setIsLoading;
  const [error, setError] = useState(null);

  const postData = useCallback(async (endpoint, formData) => {
    setLoading(true);
    setError(null);

    let token = Cookies.get("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/api/${endpoint}`,
        {
          method: "POST",
          headers: myHeaders,
          body: formData,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      let result = false;
      if (response.status === 200) {
        result = true;
      }
      if (response.status === 201) {
        result = true;
      }
      //   const result = await response.json();
      return result;
    } catch (error) {
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  return { postData, error };
};

export default usePost;

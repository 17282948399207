import Dep from "../../pages/Home/Dep";

const Departments = ({ deps, setSectionId }) => {
  return (
    <section className=" my-4 p-3">
      <div>
        <h1 className="subtitle">الاقسام</h1>
      </div>
      <div className=" flex gap-2 my-3 w-full overflow-x-auto">
        <button onClick={() => setSectionId("all")}>
          <Dep color={"#2761FF"} src={"./Images/icon.PNG"} name="الكل" />
        </button>
        {deps.map((e, index) => (
          <button key={index} onClick={() => setSectionId(e.id)}>
            <Dep
              key={index}
              color={e.description}
              src={`${process.env.REACT_APP_URL}/${e.image_path}`}
              name={e.name}
            />
          </button>
        ))}
      </div>
    </section>
  );
};
export default Departments;

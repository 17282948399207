import React, { useState } from "react";

export default function HorizCoupon({ code, percent, name, src, desc }) {
  const [copied, setCopiedState] = useState(false);
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(code).then(() => {
        setCopiedState(true);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = code;
      document.body.appendChild(textArea);
      //   textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        setCopiedState(true);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  };
  return (
    <div className=" relative transition-all ease-in-out duration-500 hover:cursor-pointer hover:sh-2 hover:scale-[1.03] bg-white  mx-auto my-2 p-2 w-[160px] min-h-[300px] ">
      <section>
        <section className=" h-[100px] border-b border-dashed border-[#0D8F75] w-full py-2 text-center  ">
          <section className=" flex gap-1 justify-center items-center ">
            <h3 className=" text-[#CCCCCC] text-[13px] font-[700] ">يصل الي</h3>
            <h1 className=" text-black text-[22px] font-[700] ">{percent}</h1>
          </section>
          <section className=" mt-1 px-2">
            <button
              onClick={handleCopy}
              className=" bg-[#0D8F75] text-white text-[11px] rounded-[5px] font-[700] text-center mx-auto h-[25px] w-[96px]  "
            >
              {!copied ? (
                <>
                  {" "}
                  نسخ الكود <i className="fa-regular fa-clone mr-2"></i>
                </>
              ) : (
                <>
                  تم النسخ <i className="fa-solid fa-check mr-2"></i>
                </>
              )}
            </button>
          </section>
        </section>
      </section>
      <section className=" w-full p-3  h-fit ">
        <section className=" flex gap-2 items-center">
          <img
            src={src}
            className=" h-[30px] w-[30px] mx-1 rounded-full "
            alt="code"
          />
          <h1 className=" text-[#0D8F75] font-[700] text-[15px] ">{name}</h1>
        </section>
        <p className="  mx-2 my-4 text-[#BFBFBF] font-[400] text-[14px] ">
          {desc}
        </p>
      </section>
      <section className=" absolute left-[-15px] top-[95px] w-[30px] h-[30px] bg-[#F2F3F7] rounded-full "></section>
      <section className=" absolute right-[-15px] top-[95px] w-[30px] h-[30px] bg-[#F2F3F7] rounded-full "></section>
    </div>
  );
}

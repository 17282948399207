import React, { useEffect, useState } from "react";
import Title from "../../components/layout/Title";
import StoreWide from "../Home/StoreWide";
import useFetch from "../../utils/useGet";

export default function Search() {
  const [stores, setStores] = useState([]);
  const [search, setSearch] = useState("");
  const { data } = useFetch("user/stores/all");

  useEffect(() => {
    if (data) {
      setStores(data);
    }
  }, [data]);

  let filterdStores = stores.filter((e) => e.name.toLowerCase().includes(search.toLowerCase()));
  return (
    <div className=" min-h-screen bg-[#F2F3F7]">
      <Title text="البحث" />
      <div className=" p-5">
        <section //search bar
          className=" w-full bg-[#FAFAFA]  rounded-[5px] py-2 px-4 flex items-center justify-start gap-2"
        >
          <i className="fa-solid fa-magnifying-glass text-[#0D8F75]"></i>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="قم بالبحث هنا"
            className=" bg-[#FAFAFA] outline-none p-2 font-[700]  w-full "
            type="text"
          />
        </section>
        <div className=" px-3 w-full overflow-hidden flex flex-wrap gap-3 my-6">
          {filterdStores.map((e, index) => {
            return (
              <StoreWide
                id={e.id}
                key={index}
                path={`/store?id=${e.id}`}
                name={e.name}
                src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                desc={e.description}
              />
            );
          })}
          {filterdStores.length === 0 && (
            <p className=" z-0 w-full flex items-center justify-center text-[#0D8F75] font-[700] text-[25px] h-[40vh]">
              لا يوجد نتائج
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function StoreWide({ path, src, name, desc }) {
  return (
    <Link className="mx-auto w-full md:w-[350px] lg:w-[600px]" to={path}>
      <section className="mx-auto hover:cursor-pointer shadow-lg hover:translate-y-0.5 hover:scale-[1.02] transition-all ease-in-out duration-300 flex items-center gap-3 my-2 bg-white p-5 w-full md:w-[350px] lg:w-[600px]  rounded-[10px]">
        <img
          className="h-[82px] md:h-[92px] w-[82px] md:w-[92px] rounded-full"
          src={src}
          alt="store"
        />
        <section>
          <h1 className=" mb-2 subtitle">{name}</h1>
          <h4 className=" text-[#999999] text-[12px] font-[700]">{desc}</h4>
        </section>
      </section>
    </Link>
  );
}

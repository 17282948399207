import React, { useEffect, useState } from "react";

import DownOutPut from "../../components/assets/DownOutPut";
import FilterBT from "./FilterBT";
import useFetch from "../../utils/useGet";
import StoresLayout from "../../components/layout/StoresLayout";
import Ads from "../../components/layout/Ads";
import Departments from "../../components/layout/Departments";
import MostFamousStores from "../../components/layout/MostFamousStores";

export default function Home() {
  // const [reload, setRelaod] = useState(false);
  const { data } = useFetch("user/advertisements/all");

  let [imgs, setImages] = useState([]);

  useEffect(() => {
    data && setImages(data.reverse());
  }, [data]);

  let [deps, setDeps] = useState([]);
  const allDeps = useFetch("user/sections/all").data;
  const [sectionId, setSectionId] = useState("all");

  useEffect(() => {
    if (allDeps) {
      setDeps(allDeps);
    }
  }, [allDeps]);

  const [openFilter, setOpenFilter] = useState(false);
  const toggleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  const [filter, setFilter] = useState("newest");

  const setFilterHndeller = (val) => {
    setFilter(val);
    toggleOpenFilter();
  };
  const filters = [
    { name: "تمت اضافته مؤخرا", val: "newest" },
    { name: "الاكثر شيوعا", val: "pouplar" },
    { name: "الاكثر استخداما", val: "usest" },
    { name: "الاكثر متابعة", val: "followest" },
    { name: "ابجديا", val: "alphapetclly" },
  ];

  return (
    <div>
      <Ads imgs={imgs} />

      <Departments deps={deps} setSectionId={setSectionId} />

      <MostFamousStores />

      <StoresLayout sectionId={sectionId} setSectionId={setSectionId} />

      <DownOutPut close={toggleOpenFilter} open={openFilter}>
        <div className=" px-3">
          <h1 className="loginTitle text-center">ترتيب حسب </h1>
          {filters.map((e) => (
            <FilterBT
              key={e.val}
              filter={filter}
              val={e.val}
              set={setFilterHndeller}
              text={e.name}
            />
          ))}
        </div>
      </DownOutPut>
    </div>
  );
}

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const Ads = ({ imgs }) => {
  return (
    <section className="p-3">
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 4000, // Time delay in milliseconds
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {imgs.map((e) => (
          <SwiperSlide key={e.image_path}>
            <img
              className="w-full h-[200px] md:h-[230px] md:w-[90%] rounded-md mx-auto"
              src={`${process.env.REACT_APP_URL}/${e.image_path}`}
              alt={`AD - ${e.image_path}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};
export default Ads;

import React, { createContext, useState } from "react";
import Cookies from "js-cookie";

export const isLogged = createContext();

export default function InStore({ children }) {
  const isin = Cookies.get("token") ? true : false;
  const roleCookie = Cookies.get("role");
  const [logged, setLogged] = useState(isin);
  const [role, setRole] = useState(roleCookie || null);

  return (
    <isLogged.Provider value={{ logged, setLogged, role, setRole }}>
      {children}
    </isLogged.Provider>
  );
}

import React from "react";

export default function FilterBT({ text, filter, val, set }) {
  return (
    <button
      onClick={() => set(val)}
      className={` my-1 text-center w-full text-[14px] font-[700] h-[45px] rounded-[10px] ${val === filter ? 'bg-[#0D8F75] text-[#fff]' :'bg-[#CCCCCC]/50 text-[#999999]'} `}
    >
      {text}
    </button>
  );
}

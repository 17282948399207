import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import InStore from "./utils/inStore";
import LoadStore from "./utils/isLoadingStore";
import { GoogleOAuthProvider } from "@react-oauth/google";
import NotiFicationProvider from "./utils/notificationsStore";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <InStore>
          <LoadStore>
            <NotiFicationProvider>
              <Suspense>
                <App />
              </Suspense>
            </NotiFicationProvider>
          </LoadStore>
        </InStore>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Link } from "react-router-dom";
import useFetch from "../../utils/useGet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const MostFamousStores = () => {
  const famStores = useFetch("user/stores/all").data;

  return (
    <section className="my-4 p-3">
      <div>
        <h1 className="subtitle">اشهر المتاجر</h1>
      </div>
      <Swiper
        className="mySwiper mt-6 max-w-7xl !mx-0"
        slidesPerView={
          window.innerWidth > 1024
            ? 8
            : window.innerWidth > 768
            ? 4
            : window.innerWidth > 425
            ? 3
            : 2
        }
        loop={true}
      >
        {famStores?.slice(0, 10).map((e, index) => (
          <SwiperSlide key={e.image_path}>
            <Link to={`/store?id=${e.id}`} key={index} className="mb-1">
              <div className="bg-white shadow-md text-center p-4 w-[120px] h-[140px] rounded-[10px]">
                <img
                  alt={`department ${e.name}`}
                  className="size-full object-contain mx-auto"
                  src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                />
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};
export default MostFamousStores;

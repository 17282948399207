import { createContext, useEffect, useState } from "react";
import useFetch from "./useGet";

export const notificationContext = createContext();

export default function NotiFicationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const [reload, setRelaod] = useState(false);
  // const audio = useRef(null);

  const { data } = useFetch("user/notifications/myNotifacation", reload);

  useEffect(() => {
    if (data) {
      setNotifications(data.reverse());
      // if (data.length > 0) {
      // audio&& audio.current?.play();
      // }
    }
  }, [data]);

  const refetch = () => {
    setRelaod(!reload);
  };

  return (
    <notificationContext.Provider
      value={{
        notifications,
        number: notifications.length,
        refetch,
      }}
    >
      {children}
    </notificationContext.Provider>
  );
}

import React from "react";
import ReactDOM from "react-dom";

export default function DownOutPut({ close, children, open }) {
  return ReactDOM.createPortal(
    <div
      className={` ${!open && "hidden"} ${
        open ? "open" : "close"
      } fixed justify-center overflow-hidden almarai-regular rtl z-50 top-0 left-0 h-[100vh] w-full`}
    >
      <div onClick={close} className="h-[100vh] w-full bg-black/20"></div>
      <div
        className={`overflow-auto pb-24 md:pb-4 px-4 pt-4 bottom-0 absolute ${
          open ? "out" : "in"
        } w-full  md:max-w-[700px] mx-auto rounded-t-[20px] max-h-[80vh] md:max-h-[100vh] bg-white`}
      >
        <div>{children}</div>
      </div>
    </div>,
    document.body // Renders the component at the root level of the DOM
  );
}

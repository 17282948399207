import { Route, Routes } from "react-router";
import "./App.css";
import "./pages/pages.css";
import "./utils/all.min.css";
import Error from "./components/err/Err";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUo";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home/Home";
import Search from "./pages/search/Search";
import NotiFication from "./pages/Notification/NotiFication";
import SecondLayout from "./components/layout/SecondLayout";
import Points from "./pages/Points/Points";
import Store from "./pages/Store/Store";
import Fav from "./pages/Fav/Fav";
import Account from "./pages/acc/Account";
import Join from "./pages/Join/Join";
import Password from "./pages/settings/Password";
import Delete from "./pages/settings/Delete";
import AccountSettings from "./pages/settings/Account";
import Policies from "./pages/help/Policies";
import Help from "./pages/help/Help";
import { load } from "./utils/isLoadingStore";
import { useContext } from "react";
import Loader from "./components/Loader/Loader";
import LogLayout from "./components/layout/LogLayout";
import SignUpSuccess from "./pages/auth/SignUpSuccess";
import Products from "./pages/products";

function App() {
  const isLoading = useContext(load).isLoading;
  return (
    <div className="almarai-regular rtl">
      {isLoading && <Loader />}
      <LogLayout />
      <Routes>
        <Route
          path="*"
          element={
            <Layout>
              <Error />
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup-success" element={<SignUpSuccess />} />
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/points"
          element={
            <SecondLayout text="النقاط">
              <Points />
            </SecondLayout>
          }
        />
        <Route
          path="/fav"
          element={
            <SecondLayout text="المفضلة">
              <Fav />
            </SecondLayout>
          }
        />
        <Route
          path="/account"
          element={
            <SecondLayout text="المساعدة">
              <Account />
            </SecondLayout>
          }
        />
        <Route path="/products" element={<Products />} />
        <Route path="/search" element={<Search />} />
        <Route path="/notifications" element={<NotiFication />} />
        <Route path="/store" element={<Store />} />
        <Route path="/join" element={<Join />} />
        <Route path="/passwsord-settings" element={<Password />} />
        <Route path="/deleteaccount" element={<Delete />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/help" element={<Help />} />
      </Routes>
    </div>
  );
}

export default App;


import React from "react";
import Nav from "./Nav";
import DownBar from "./DownBar";

export default function Layout({ children }) {
  return (
    <div className="relative  py-24 bg-[#F2F3F7] min-h-screen">
      <div className="relative z-10">
        <Nav />
      </div>

      <div className="absolute pb-20 bg-[#F2F3F7]  top-24 left-0 w-full z-0">
        {children}
      </div>

      <div className="relative z-10">
        <DownBar />
      </div>
    </div>
  );
}

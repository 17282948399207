import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isLogged } from "../../utils/inStore";
import { load } from "../../utils/isLoadingStore";
import Cookies from "js-cookie";

export default function Account() {
  const navigate = useNavigate();
  const { setIsLoading } = useContext(load);
  const { setLogged, logged } = useContext(isLogged);

  const logOutHandeller = () => {
    setIsLoading(true);
    const token = Cookies.get("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URL}/api/user/logout`, requestOptions).then(
      (res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status === 200) {
          Cookies.remove("token");
          Cookies.remove("id");
          setLogged(false);
          navigate("/login");
        }
      }
    );
  };
  return (
    <div className=" p-3">
      {/* <div>
        <h1 className=" my-3 text-[23px] font-[700] ">اعدادات الحساب</h1>
        <section className=" my-2 bg-white w-full rounded-[10px] p-4 ">
          <Link
            className=" border-b text-[#0D8F75] tex-[15px] font-[700] flex items-center gap-3 px-2 py-5  "
            to="/account-settings"
          >
            <i className="fa-solid fa-user " />
            <h1>الحساب الشخصي</h1>
          </Link>
          <Link
            className=" text-[#0D8F75] tex-[15px] font-[700] flex items-center gap-3 px-2 py-5  "
            to="/passwsord-settings"
          >
            <i className="fa-solid fa-lock " />
            <h1> الامان</h1>
          </Link>
        </section>
      </div> */}
      <div>
        <h1 className=" my-3 text-[23px] font-[700] "> اخري</h1>
        <section className=" my-2 bg-white w-full rounded-[10px] p-4 ">
          <Link
            className=" border-b text-[#0D8F75] tex-[15px] font-[700] flex items-center gap-3 px-2 py-5  "
            to="/help"
          >
            <i className="fa-regular fa-comment"></i>
            <h1>مركز المساعدة</h1>
          </Link>
          <Link
            className=" text-[#0D8F75] tex-[15px] font-[700] flex items-center gap-3 px-2 py-5  "
            to="/policies"
          >
            <i className="fa-solid fa-sheet-plastic"></i>
            <h1> الشروط والاحكام</h1>
          </Link>
        </section>
      </div>
      <div>
        <section className="my-4 bg-white w-full rounded-[10px] p-2">
          <Link
            className=" text-[#0D8F75] tex-[15px] font-[700] flex items-center gap-3 px-2 py-5  "
            to="/join"
          >
            <i className="fa-solid fa-users"></i>
            <h1> انضمام مع العائلة</h1>
          </Link>
        </section>
      </div>
      {logged && (
        <div>
          <button
            onClick={logOutHandeller}
            className="w-full text-center flex items-center justify-center my-10 gap-10 bg-[#F91717] text-white rounded-[10px] text-[18px] font-bold py-3"
          >
            <h1>تسجيل الخروج </h1>
            <i className="fa-solid fa-right-from-bracket"></i>
          </button>
        </div>
      )}
    </div>
  );
}

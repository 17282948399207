import React from "react";

export default function Loader() {
  return (
    <div className=" fixed top-0 left-0 z-40 h-screen w-full bg-black/30 flex items-center justify-center">
      <section>
        <div className=" loader "></div>
      </section>
    </div>
  );
}
